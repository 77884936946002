import { fetchRetryIfFails } from "./helperFunctions";
import Cookies from "universal-cookie";

const GET_CHANNELS_API_URL = 'https://pt43iei9z8.execute-api.eu-west-1.amazonaws.com/dev/channels'

export async function getChannelsFromApi(apiKey){
    // Lägg till header med key 'Authorization' value 'Bearer jwt'
    var response;
    let url = GET_CHANNELS_API_URL;

    const cookies = new Cookies();
    const access_token = cookies.get('access_token');
    var requestData = {}

    if(apiKey){
        requestData = {
            method: 'GET',
            headers: {
                'Authorization' : `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        }

    } else{
        requestData = {
            method: 'GET',
            headers: {
                'Authorization' : `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            }
        }
    } 
    response = await fetchRetryIfFails(url, requestData, 'Could not fetch channels. Error:')
    if (!response) return [];
    var channelList = await response.json();
    return channelList;
}

