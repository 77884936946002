import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeDynamicState, fetchInitialData } from '../redux/slices/dynamicReducer'
import {useTransition, animated} from '@react-spring/web';
import "react-image-lightbox/style.css";
import './index.css'
import { getChannelName } from '../services/helperFunctions';
import ImageGallery from '../components/ImageGallery';
import LoadIndicator from '../components/loadIndicator';
import { Header } from '../components/Header';

const StaticGallery = () => {
    // const reduxData = useSelector((state)=> state.dynamic.data);
    const channelId = useSelector((state) => state.dynamic.channelId);
    const channels = useSelector((state) => state.dynamic.channels)
    const channelName = getChannelName(channels, channelId)

    const [transitionGallery, setTransitionGallery] = useState(false);
    const dispatch = useDispatch()

    const allImages = useSelector((state) => state.dynamic.allImages);
    const displayLoad = useSelector((state) => state.dynamic.loading);
  
    useEffect(() => {
        async function fetchPictures(){
            dispatch(changeDynamicState({ key: "timestamp", value: 0 })) //Edge case when returning from static page
            dispatch(changeDynamicState({key: 'loading', value: true}));
            // dispatch(changeDynamicState({key:"data",value:await getImagesFromApi({apiKey, shuffle, channelId})}));
            dispatch(fetchInitialData(0));
        }
        fetchPictures();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelId]);

    const transitions = useTransition(transitionGallery, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: transitionGallery,
        delay: 0,
        // config: config.molasses,
        config: {
            tension: 190,
            friction: 50,
        },
        onRest: () => setTransitionGallery(true),
      });

    return (
        <div className='wrapper'>
            <Header channelName={channelName}/>
            {displayLoad || allImages.length <= 0 ? <LoadIndicator displayText="Setting up Image Gallery" color="#715aff"/> : 
                <div className="gallery-wrapper">
                    {transitions((styles, item) => (
                        item &&
                        <animated.div style={styles}>
                            <ImageGallery
                                imagesToDisplay={allImages}
                                type="static"
                            />
                        </animated.div>
                    ))}
                </div>
            }
        </div>
    )
}

export default StaticGallery;