import { update } from '@react-spring/core';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getChannelsFromApi } from '../../services/getChannelsFromApi'
import { getDynamicData } from "../../services/getDynamicGallery"
import { shuffleArray, sortDate, updateImages } from "../../services/helperFunctions"

// First, create the thunk
const fetchChannels = createAsyncThunk(
  'dynamic/fetchChannels',
  async (value, thunkAPI) => {
    const apiKey = thunkAPI.getState().global.apiKey;
    return await getChannelsFromApi(apiKey);
  }
);

const fetchInitialData = createAsyncThunk(
  'dynamic/fetchInitialData',
  async (value, thunkAPI) => {
    console.log('fetching data', value)
    const dynamicState = thunkAPI.getState().dynamic;
    const apiKey = thunkAPI.getState().global.apiKey;
    const res = {data: await getDynamicData(apiKey, 0, dynamicState.channelId), totalPictures: value}

    const updatedImages = await updateImages(res.data)
    
    res.data = [...updatedImages]
    return res;
  }
);

const updateNewData = createAsyncThunk(
  'dynamic/updateNewData',
  async (value, thunkAPI) => {
    const dynamicState = thunkAPI.getState().dynamic;
    const apiKey = thunkAPI.getState().global.apiKey;
    const res = {newData:await getDynamicData(apiKey, dynamicState.timestamp,dynamicState.channelId),data:dynamicState.data,timestamp:dynamicState.timestamp}

    const updatedImages = await updateImages(res.newData)
 
    res.data = [...updatedImages];
    return res;
  }
);


const dynamicSlice = createSlice({
    name: 'dynamic',
    initialState: {
      newData:[],
      timestamp:0,
      totalPictures: 11,
      updateInterval: 10000,
      channelId:"C02G7FUTE1W",
      galleryType: 'static',
      darkMode: false,
      channels: [],
      loading: false,
      allImages: [],
      imagesToDisplay: [],
      latestImageTimeStamp: 0,
    },
    reducers: {
      changeDynamicState(state,action){
        state[action.payload.key] = action.payload.value
      }, 
      changeChannel(state, action){ //Reset all states when changing channel
        state["channelId"] = action.payload.value;
        state["timestamp"] = 0;
        state["newData"] = [];
        state["loading"] = false;
        state["allImages"]= [];
        state["imagesToDisplay"] = [];
      }
    },

    extraReducers: {
      // Add reducers for additional action types here, and handle loading state as needed
      [fetchInitialData.fulfilled]: (state, action) => { //Check that pictures are fetched 
        (action.payload && action.payload.data && action.payload.data[0] && (state.timestamp = (sortDate([...action.payload.data])[0].picture_date)));
        state.newData = [];

        state.allImages = [...action.payload.data];
        state.imagesToDisplay = action.payload.data.length > state.totalPictures ? [...action.payload.data.slice(0, state.totalPictures)] : [...action.payload.data];
        state.loading = false;
      },

      [fetchChannels.fulfilled]:(state, action) =>{
        state.channels = (action.payload)
      },
      
      [updateNewData.fulfilled]:(state, action) =>{ //Check that the new data is displayed first 
        (action.payload && action.payload.newData && action.payload.newData[0] && (state.timestamp = (sortDate([...action.payload.newData])[0].picture_date)))
        state.newData = sortDate([...action.payload.newData]) //Sort the new data also (not tested)

        const previouslyDisplayedImages = [...state.imagesToDisplay].reverse();
        let tmpAllImages = [...state.allImages];
        tmpAllImages = [...tmpAllImages, ...previouslyDisplayedImages];
        tmpAllImages.splice(0, previouslyDisplayedImages.length);

        if(state.newData.length > 0) {
          tmpAllImages = [...state.newData, ...tmpAllImages];
        }

        // Filtering duplicate entries
        tmpAllImages = tmpAllImages.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.place === thing.place && t.picture_id === thing.picture_id
          ))
        )  

        state.allImages = [...tmpAllImages];
        state.imagesToDisplay = tmpAllImages.length > state.totalPictures ? tmpAllImages.splice(0, state.totalPictures) : tmpAllImages;
      }
    }
  });

export {fetchInitialData, fetchChannels,updateNewData}

export const { changeDynamicState, changeChannel } = dynamicSlice.actions

export default dynamicSlice.reducer
