import React from "react";
import { Router, Switch, Redirect } from "react-router-dom"; 
import browserHistory from "./history"; 
import { paths } from "./paths"; 
import { DefaultTemplate } from "../defaultTemplate";
import DynamicGallery from "../pages/DynamicGallery";
import StaticGallery from "../pages/StaticGallery";
import { withCookies } from 'react-cookie';


export const Routes = () => {
    // eslint-disable-next-line no-unused-vars
    const StaticGalleryCookie = withCookies(StaticGallery);
    const DynamicGalleryCookie = withCookies(DynamicGallery)

    return(
    <Router history={browserHistory}>
        <Redirect from="/auth" to={paths.HOME} />
        <Switch>
            <DefaultTemplate exact path={paths.HOME} component={DynamicGalleryCookie}/> 
            <DefaultTemplate exact path={paths.STATIC} component={StaticGalleryCookie}/>
            <DefaultTemplate exact path={paths.DYNAMIC} component={DynamicGalleryCookie}/>
        </Switch>
    </Router>
    )}
;