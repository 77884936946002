import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import {
  changeChannel,
  fetchInitialData,
} from '../../redux/slices/dynamicReducer'

export const DropDownButton = ({ channels, currentChannel, helperText }) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState(false)
  const [defaultChannel, setDefaultChannel] = useState('')

  useEffect(() => {
    if (channels.length > 0) {
      let tempChannels = []
      channels.map((channel) => {
        if (channel.channel_id === currentChannel) {
          setDefaultChannel({
            value: channel.channel_id,
            label: `#${channel.channel_name}`,
          })
        }
        tempChannels.push({
          value: channel.channel_id,
          label: `#${channel.channel_name}`,
        })
      })
      setOptions(tempChannels)
    }
  }, [channels])

  const onChange = (selectedOption) => {
    dispatch(changeChannel(selectedOption))
    dispatch(fetchInitialData())
  }

  return (
    <>
      {helperText && (
        <p className="dropDownHelperText" style={dropDownStyles.helperText}>
          {helperText}
        </p>
      )}
      {options ? (
        <Select
          className="channel-dropdown"
          onChange={onChange}
          options={options}
          defaultValue={defaultChannel}
          styles={dropDownStyles}
        />
      ) : (
        <div style={dropDownStyles.errorContainer}>
          <p style={dropDownStyles.errorText}>Could not load channels</p>
        </div>
      )}
    </>
  )
}

const dropDownStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  control: (provided) => ({
    ...provided,
    width: 200,
    marginBottom: '30px',
    marginLeft: '30px',
    border: '1px solid #7573B6',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '20px',
    color: state.isFocused ? 'white' : 'gray',
    backgroundColor: state.isFocused ? '#575C98' : 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    width: 200,
    color: 'gray',
    fontWeight: 'bold',
  }),
  helperText: {
    fontSize: '10pt',
    fontWeight: '400',
    margin: '0px',
    marginLeft: '30px',
    color: 'white',
    marginTop: '30px',
  },
  errorContainer: {
    backgroundColor: 'lightgrey',
    width: 'max-content',
    padding: '8px 8px',
    textAlign: 'center',
    borderRadius: '4px',
    marginLeft: '30px',
    marginBottom: '30px',
    opacity: 0.6,
  },

  errorText: {
    color: 'black',
    margin: '0px',
    fontSize: '12pt',
  },
}
