import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: 'global', 
    initialState: {
        loggedIn: false,
        apiKey: '',
    }, 
    reducers: {
        changeGlobalState(state, action){
            state[action.payload.key] = action.payload.value
        },

        apiKeySubmitted(state, action){
            state['loggedIn'] = true
            state['apiKey'] = action.payload.apiKey
        },
    },
})

export const { changeGlobalState, apiKeySubmitted } = globalSlice.actions

export default globalSlice.reducer