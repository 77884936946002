import { shuffleArray, fetchRetryIfFails } from "./helperFunctions"
import Cookies from "universal-cookie";
const GET_IMAGES_API_URL = 'https://pt43iei9z8.execute-api.eu-west-1.amazonaws.com/dev/images'
//const GET_ALL_CHANNELS;

export async function getImagesFromApi({apiKey, timestamp=0, shuffle, channelId='C02G7FUTE1W'}){
    var response;
    let url = GET_IMAGES_API_URL;

    url += "?after=" + timestamp + "&channelId=" + channelId;

    const cookies = new Cookies();
    const access_token = cookies.get('access_token');
    var requestData = {}

    if(apiKey){
        requestData = {
            method: 'GET',
            headers: {
                'Authorization' : `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        }

    } else{
        requestData = {
            method: 'GET',
            headers: {
                'Authorization' : `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            }
        }
    } 

    response = await fetchRetryIfFails(url, requestData, "Could not fetch images. Error: ")
    if (!response) return [];
    var pictureList = await response.json();
    shuffle && shuffleArray(pictureList);
    return pictureList;
}