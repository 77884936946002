import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { changeDynamicState, fetchInitialData, updateNewData } from '../redux/slices/dynamicReducer';
import { useInterval } from '../services/useInterval';
import {useTransition, animated} from '@react-spring/web';
import LoadIndicator from '../components/loadIndicator';
import "react-image-lightbox/style.css";
import ImageGallery from '../components/ImageGallery';
import SettingsButton from '../components/settingsButton';
import { Header } from '../components/Header';
import { getChannelName } from '../services/helperFunctions';

const DynamicGallery = () => {
    const totalPictures = useSelector((state) => state.dynamic.totalPictures);
    const channelId = useSelector((state) => state.dynamic.channelId);
    const channels = useSelector((state) => state.dynamic.channels);
    const imagesToDisplay = useSelector((state) => state.dynamic.imagesToDisplay);
    const displayLoad = useSelector((state) => state.dynamic.loading);
    const updateInterval = useSelector((state) => state.dynamic.updateInterval);
    const channelName = getChannelName(channels, channelId)

    const dispatch = useDispatch();
    const [timer, setTimer] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [transitionGallery, setTransitionGallery] = useState(false);

    useEffect(() => {
    const timerEvents = async () => {
        //On initial rendering
        if (timer === 0) {
            dispatch(changeDynamicState({key: 'loading', value: true}));
            dispatch(changeDynamicState({ key: "timestamp", value: 0 })); //Edge case when returning from static page
            dispatch(fetchInitialData(totalPictures));
        } else {
            setTransitionGallery(false);
            updateGallery();
        }
    }
    timerEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer, channelId]);

    const updateGallery = async () => {
        dispatch(updateNewData());
    }

    const handleTimer = () => {
        setTimer(timer + 1)
    }

    useInterval(() => {
        handleTimer();
    }, updateInterval);

    //To change transition speed and feel; tweak the delay, tension and friction parameters.
    const transitions = useTransition(transitionGallery, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: transitionGallery,
        delay: 0,
        // config: config.molasses,
        config: {
            tension: 120,
            friction: 20,
        },
        onRest: () => setTransitionGallery(true),
      });

    return (
        <div className='wrapper'>
            <Header channelName = {channelName}/>
            <div className='toolbar-wrapper'>
               <SettingsButton />
            </div>
            {displayLoad || imagesToDisplay.length <= 0 ? <LoadIndicator displayText="Setting up Image Gallery" color="#715aff"/> : 
                <div className="gallery-wrapper">
                        {transitions((styles, item) => (
                            item &&
                            <animated.div style={styles}>
                                <ImageGallery
                                    imagesToDisplay={imagesToDisplay}
                                    type="dynamic"
                                />
                            </animated.div>
                        ))}
                </div>}
            
        </div>
    )
}

export default DynamicGallery