import { Route } from 'react-router-dom'
import SideMenu from '../components/SideMenu'

export const DefaultTemplate = (props) => {
  return (
    <div>
      <SideMenu>
        <Route {...props} />
      </SideMenu>
    </div>
  )
}
