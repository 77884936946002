export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return(array)
}

export function sortArray(a,b) {
    if ( a.picture_date > b.picture_date ){
        return -1;
      }
      if ( a.picture_date < b.picture_date ){
        return 1;
      }
      return 0;
}

export function modifiedNewData(array){
    array.pop()
    return array
}
export function modifiedGallery(array,picture){
    array.push(picture)
    array.shift();
    return array
}

export function modifiedBag(array){
    array.shift()
    return array
}

export function addToBag(array,picture,index){
    array.splice(index-2,0,picture)
    return array
}

export function sortDate(array){
    const arr=[...array];
    return arr.sort((a,b)=>sortArray(a,b));
}

export function getChannelName(channels, channelId){
    const channel = channels.find(channel => channel.channel_id === channelId)
    return channel ? channel.channel_name : ''
}

async function appendWidthAndHeightToImage(image){
    const gcd = (a, b) => b === 0 ? a : gcd(b, a % b);
    const img = new Image();
    img.src = image.src;
    await img.decode();
    const greatestCommonDenominator = gcd(img.width, img.height);
    image.width = img.width / greatestCommonDenominator;
    image.height = img.height / greatestCommonDenominator;

    return image
}

export async function updateImages(images){
    let updatedImages = images;
    updatedImages = await Promise.all(updatedImages.map(async (img) => {
        img.src = img.picture_url;

        //temp solution for images without these props
        if(!img.width || !img.height) {
            img = await appendWidthAndHeightToImage(img)
        }
        return img
    }))
    return updatedImages
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

export async function fetchRetryIfFails(url, requestInfo, errorMsg = 'Error'){
    var responseNotComplete = true;
    var response;
    while(responseNotComplete){
        try {
            response = requestInfo ? await fetch(url, requestInfo) : await fetch(url);
            responseNotComplete = false;
        } catch (error) {
            console.log(errorMsg + error);
            await sleep(5000);
        }
    }
    return response;
}