import React,{useEffect, useState} from 'react'
import { Routes } from './routes/routes'
import { useSelector,useDispatch } from 'react-redux'
import { changeGlobalState } from "./redux/slices/globalReducer"
import { useCookies } from 'react-cookie';
import randomString from 'randomstring';
import { InvisibleForm } from './components/invisibleForm';
import { authUser } from './services/authUser';
import LandingPage from './pages/LandingPage';

const config = require('./config.json');

function App() {
  const loggedIn = useSelector((state) => state.global.loggedIn)

  // eslint-disable-next-line no-unused-vars
  const [useApiKey, setUseApiKey] = useState(false)
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const dispatch = useDispatch();

  const CLIENT_ID = config.CLIENT_ID;
  const REDIRECT_URI = config.REDIRECT_URI;
  

  const generateStateKey = () => {
    const stateKey = randomString.generate(10);
    const cookieDate = new Date();
    
    cookieDate.setMinutes(cookieDate.getMinutes() + 30);
    setCookie('state', stateKey, {expires:cookieDate});

    return stateKey;
  }

  const generateNonceKey = () => {
    let nonceKey = randomString.generate(6);
    const cookieDate = new Date();
    
    // Use existing nonce cookie if one already exists
    if(!cookies.nonce) {
      cookieDate.setMinutes(cookieDate.getMinutes() + 4);
      setCookie('nonce', nonceKey, {expires:cookieDate});
    } else {
      nonceKey = cookies.nonce
    }
    
    return nonceKey;
  }

  useEffect(() => {
    const setupCookiesOnLaunch = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      const cookieDate = new Date();
      cookieDate.setMinutes(cookieDate.getMinutes() + 720);
      
      if(code){
          
          //TODO: try setCookie(await fetch authurl(code), expires: cookieDate) so make the first auth-call here in with the code in order to get the jwt (and set the cookie to the jwt that is returned)

          const nonce = cookies.nonce
          const access_token = await authUser(code, nonce, REDIRECT_URI)
          if(access_token){
            dispatch(changeGlobalState({key:"loggedIn", value: true}))
            setCookie('access_token', access_token, {expires: cookieDate});
            setCookie('expire', cookieDate.valueOf(), {expires: cookieDate}); //add expire cookie to keep track of the expire time
          }
          
        }
    }

    const checkApiKeyQueryParam = () => {
      const queryParams = new URLSearchParams(window.location.search)
      setUseApiKey(queryParams.has('useApiKey'))
    }

    checkApiKeyQueryParam()
    setupCookiesOnLaunch();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  return (
    (loggedIn)
    ?  <Routes/>
    : (useApiKey)
      ? <InvisibleForm/>
      : 
          <LandingPage 
            nonce={generateNonceKey()}
            state={generateStateKey()}
            clientID={CLIENT_ID}
            REDIRECT_URI={REDIRECT_URI}
          />
  );
}

export default App;
