import { useState, useEffect } from 'react'
import Lightbox from 'react-image-lightbox'

const LightboxContainer = ({ startingIndex, images, setClose }) => {
  const [lightboxState, setLightboxState] = useState(null)

  useEffect(() => {
    const nrOfImages = images.length - 1
    const nextIndex = startingIndex === nrOfImages ? 0 : startingIndex + 1
    const prevIndex = startingIndex === 0 ? nrOfImages : startingIndex - 1
    setLightboxState({
      current: { index: startingIndex, src: images[startingIndex].src },
      next: { index: nextIndex, src: images[nextIndex].src },
      prev: { index: prevIndex, src: images[prevIndex].src },
    })
  }, [])

  const updateLightboxState = (direction) => {
    const nrOfImages = images.length - 1
    const oldCurrent = lightboxState.current.index
    const newCurrent =
      direction === 'next'
        ? oldCurrent === nrOfImages
          ? 0
          : oldCurrent + 1
        : oldCurrent === 0
        ? nrOfImages
        : oldCurrent - 1
    const next = newCurrent === nrOfImages ? 0 : newCurrent + 1
    const prev = newCurrent === 0 ? nrOfImages : newCurrent - 1

    setLightboxState({
      current: { index: newCurrent, src: images[newCurrent].src },
      next: { index: next, src: images[next].src },
      prev: { index: prev, src: images[prev].src },
    })
  }

  return (
    lightboxState && (
      <Lightbox
        mainSrc={lightboxState.current.src}
        nextSrc={lightboxState.next.src}
        prevSrc={lightboxState.prev.src}
        onCloseRequest={() => setClose()}
        onMovePrevRequest={() => updateLightboxState('prev')}
        onMoveNextRequest={() => updateLightboxState('next')}
      />
    )
  )
}

export default LightboxContainer
