import React, {useState, useEffect} from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { changeDynamicState, fetchInitialData } from '../../redux/slices/dynamicReducer';
import { useSpring, animated } from "@react-spring/web";
import Toggle from 'react-toggle';

const SettingsButton = ({canDisplayDialog}) => {

    const [displaySettingsModal, setDisplaySettingsModal] = useState(false);
    const picturesToShow = useSelector((state) => state.dynamic.totalPictures);
    const updateInterval = useSelector((state) => state.dynamic.updateInterval);
    const allPictures = useSelector((state) => state.dynamic.allImages);
    const galleryType = useSelector((state) => state.dynamic.galleryType);
    const loading = useSelector((state) => state.dynamic.loading);
    const darkMode = useSelector((state) => state.dynamic.darkMode);

    const dispatch = useDispatch();

    const animationProps = useSpring({
        height: displaySettingsModal ? 400 : 0,
        width: displaySettingsModal ? 300 : 0,
      });

    useEffect(() => {
        if(!canDisplayDialog) {
            setDisplaySettingsModal(false);
        }
    }, [canDisplayDialog])

    const handleSliderChange = (evt, type) => {
        switch(type){
            case 'totalPictures':
                dispatch(changeDynamicState({key: 'totalPictures', value: evt}));
                dispatch(fetchInitialData(evt));
                console.log(picturesToShow);
                return;

            case 'updateInterval':
                dispatch(changeDynamicState({key: 'updateInterval', value: (evt* 1000)}));
                return;
            default:
                return;
        }
    }

    const handleSettingsMenuClick = () => {
        setDisplaySettingsModal(!displaySettingsModal);
    }

    const handleDarkModeToggleChange = (newValue) => {
        console.log(newValue);
        dispatch(changeDynamicState({key: 'darkMode', value: newValue}));
    }

    const SettingsModalForDynamic = () => {
        return(
            <animated.div style={animationProps} className='settings-modal-wrapper'>
                <h6 className='settings-modal-header'>Change settings for the gallery</h6>

                <p className='settings-modal-label'>Number of pictures to show</p>
                <h3 className='settings-modal-slider-header'>{picturesToShow}</h3>
                <Slider min={1} max={allPictures ? allPictures.length : 10} defaultValue={picturesToShow} step={1} dots={true} onAfterChange={(e) => handleSliderChange(e, 'totalPictures')}/>

                <p className='settings-modal-label'>Time between updates</p>
                <h3 className='settings-modal-slider-header'>{updateInterval / 1000 + ' s'}</h3>
                <Slider min={1} max={60} defaultValue={updateInterval / 1000} step={2} dots={false} onAfterChange={(e) => handleSliderChange(e, 'updateInterval')}/>

                <p className='settings-modal-label toggle'>{darkMode ? 'Disable light mode' : 'Enable dark mode'}</p>
                <Toggle 
                    checked={darkMode}
                    onChange={(e) => handleDarkModeToggleChange(e.target.checked)}
                />
            </animated.div>
        );
    };

    const SettingsModalForStatic = () => {
        return(
            <animated.div style={animationProps} className='settings-modal-wrapper'>
                <h6 className='settings-modal-header'>Change settings for the gallery</h6>
    
                <p className='settings-modal-label'>Number of pictures to show</p>
                <h3 className='settings-modal-slider-header'>{picturesToShow}</h3>
                <Slider min={1} max={allPictures ? allPictures.length : 10} defaultValue={picturesToShow} step={1} dots={true} onAfterChange={(e) => handleSliderChange(e, 'totalPictures')}/>

                <p className='settings-modal-label toggle'>{darkMode ? 'Disable light mode' : 'Enable dark mode'}</p>
                <Toggle 
                    checked={darkMode}
                    onChange={(e) => handleDarkModeToggleChange(e.target.checked)}
                />
            </animated.div>
            );
    }
    
    return(
        <div className='settings-button-wrapper'>
            <FontAwesomeIcon icon={faCog} onClick={() => handleSettingsMenuClick()} className={loading ? 'settings-button-disabled' : ''}/>
            {
                displaySettingsModal && canDisplayDialog && galleryType === 'static' ?
                 <SettingsModalForStatic />
                 : displaySettingsModal && canDisplayDialog && galleryType === 'dynamic' ?
                 <SettingsModalForDynamic />
                 : <></>
            }
        </div>
    )
};

export default React.memo(SettingsButton);