import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux'
import { changeDynamicState, fetchChannels } from '../../redux/slices/dynamicReducer'
import { changeGlobalState } from "../../redux/slices/globalReducer"

// LIBRARIES
import { push as Menu } from 'react-burger-menu'

// COMPONENTS
import { DropDownButton } from '../dropDownButton'
import Slider from '../Slider'

import { Link } from 'react-router-dom'
import './index.css'
import SIGLogo from '../../assets/images/sig-icon-1.webp'

const SideMenu = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false)
  let totalPictures = useSelector((state) => state.dynamic.totalPictures)
  const channels = useSelector((state) => state.dynamic.channels)
  const channelId = useSelector((state) => state.dynamic.channelId)
  const [_cookies, _setCookie, removeCookie] = useCookies(['cookie-name']);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchChannels())
    // dispatch new channel id
  }, [channelId]);

  const handleGalleryTypeChange = (newValue) => {
    dispatch(changeDynamicState({key:'galleryType', value: newValue}));
  }

  const logoutUser = () => {
    removeCookie('access_token')
    dispatch(changeGlobalState({ key:"loggedIn", value: false }))
  };

  return (
    <div id="outer-container">
      <Menu
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpen={openMenu}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
      >
        <div id="menuLogoSection">
          <img src={SIGLogo} alt="Slack Image Gallery Logo" id="sideMenuLogo" />
          <p id="sideBarTitle">Slack Image Gallery</p>
        </div>
        {channels && (
          <DropDownButton
            channels={channels}
            currentChannel={channelId}
            helperText="Select Slack Channel:"
          />
        )}
        <Slider
          value={totalPictures}
          setValue={(val) =>
            dispatch(changeDynamicState({ key: 'totalPictures', value: val }))
          }
          min={5}
          max={30}
          step={1}
          sliderWidth={70}
          helperText={'Image display count'}
        />
        <Link to="/dynamic" className="sideMenuRouteLink" onClick={() => handleGalleryTypeChange('dynamic')}>
          <div className="sideMenuLinkWrapper">
            <p className="sideMenuLink">Slide show</p>
          </div>
        </Link>
        <Link to="/static" className="sideMenuRouteLink" onClick={() => handleGalleryTypeChange('static')}>
          <div className="sideMenuLinkWrapper">
            <p className="sideMenuLink">All pictures</p>
          </div>
        </Link>
        <Link className="sideMenuRouteLink" onClick={logoutUser}>
          <div className="sideMenuLinkWrapper" >
            Logout
          </div>
        </Link>
        <div className="settings-section-wrapper">
        {/*
          Disabled this component due to redundancy. 
          There might be some use for it in the furure, so we leave the code 
          in this projcet.
           
          <SettingsButton canDisplayDialog={openMenu}/>
        */}
      </div>
      </Menu>
      <main id="page-wrap">{children}</main>
    </div>
  )
}

export default SideMenu
