import React from 'react'
import './index.css'

export const Header = ({ channelName }) => {
    const cleanName = channelName
                        .split(/-|_/)
                        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
                        .join(' ')

    return (
        <div>
            <h2 className='header'>{cleanName}</h2>
        </div>
    )
}