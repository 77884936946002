import React, {useState} from "react"
import { useDispatch } from "react-redux"
import { apiKeySubmitted } from "../../redux/slices/globalReducer"
import { getChannelsFromApi } from "../../services/getChannelsFromApi"

export const InvisibleForm = () => {
    const dispatch = useDispatch()
    const [key, setKey] = useState('')

    async function handleSubmit(e) {
        e.preventDefault();
        //Ugly hack in order to handle if the api-key is wrong
        const response = await getChannelsFromApi(key)
        if(response['Message'] === 'User is not authorized to access this resource with an explicit deny'){ //If we get a message, we know that it has been an error 
            console.log("Wrong API-key, could not log in.")
        } else{
            dispatch(apiKeySubmitted({apiKey: key}))
        }  
    }

    return (
        <form onSubmit={handleSubmit} style={{display:"none"}}>
            <div>
                <label htmlFor="api">Api-key</label>
                <input type ="password" id="apiInput" onChange={e => setKey(e.target.value)}/>
            </div>
            <button type="submit" id ="submitApi">Submit</button>
        </form>
    )
}