import './index.css'

const Slider = ({
  max = 20,
  min = 5,
  step = 1,
  sliderWidth = 100,
  value,
  setValue,
  helperText,
  customStyling,
}) => {
  return (
    <div className="sliderContainer">
      {helperText && (
        <p className="sliderHelperText">
          {`${helperText}: `}
          {value}
        </p>
      )}
      <span className="sliderSpan">
        <p className="sliderValueLabel">{min}</p>
        <input
          className="slider"
          type="range"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          max={max}
          min={min}
          style={{ width: `${sliderWidth}%` }}
          step={step}
        />
        <p className="sliderValueLabel">{max}</p>
      </span>
    </div>
  )
}

export default Slider
