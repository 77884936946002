import { memo, useState } from 'react'

// LIBRARIES
import Gallery from 'react-photo-gallery'
import 'react-image-lightbox/style.css'

// COMPONENTS
import LightboxContainer from '../LightboxContainer'

// STYLING
import './index.css'

const ImageGallery = ({ imagesToDisplay }) => {
  const [showFocusedPicture, setShowFocusedPicture] = useState(false)
  const [startingIndex, setStartingIndex] = useState(0)

  const getDynamicColumns = (containerWidth) => {
    let columns = 1
    const COLUMN_MIN_WIDTH = 300
    const columnsMultiplier = Math.floor(containerWidth / COLUMN_MIN_WIDTH)
    columns *= columnsMultiplier
    return columns
  }

  const openLightbox = (evt, obj) => {
    setStartingIndex(obj.index)
    setShowFocusedPicture(true)
  }

  const closeLightbox = () => {
    setStartingIndex(0)
    setShowFocusedPicture(false)
  }

  return (
    <>
      {showFocusedPicture && (
        <LightboxContainer
          startingIndex={startingIndex}
          setClose={() => closeLightbox()}
          images={imagesToDisplay}
        />
      )}
      <Gallery
        photos={imagesToDisplay}
        columns={getDynamicColumns}
        direction="column"
        margin={2}
        onClick={openLightbox}
      />
    </>
  )
}

export default memo(ImageGallery)
