import React from 'react';
import './index.css';
import {GridLoader} from 'react-spinners';

const LoadIndicator = ({displayText, color}) => {
    return(
        <div className="load-indicator-wrapper">
            <GridLoader loading={true} color={color} size={35}/>
            <h2 className="display-text">
                {displayText}
            </h2>
        </div>
    )
};

export default LoadIndicator;