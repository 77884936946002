import { fetchRetryIfFails } from "./helperFunctions";
const AUTH_USER_API_URL = 'https://pt43iei9z8.execute-api.eu-west-1.amazonaws.com/dev/auth'

export async function authUser(code, nonce, redirectUri){
    let response;
    let url = AUTH_USER_API_URL + '?code=' + code + '&nonce=' + nonce + '&redirectUri=' + redirectUri 
    response = await fetchRetryIfFails(url, {}, 'Auth failed. Error:')
    const token = await response.json()
    return token.access_token
}

